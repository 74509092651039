document.addEventListener("DOMContentLoaded", function () {
  let puncts = document.querySelectorAll(".nav-list__item.transition:has(.header-dropdown) span");
  puncts.forEach((punct) => {
		punct.parentElement.style.setProperty('--_height', punct.parentElement.querySelector(".header-dropdown").offsetHeight + "px");
		punct.setAttribute("data-init", "true")
    punct.addEventListener("click", function () {
			puncts.forEach((pp) => {
				if(pp != punct) {
					pp.parentElement.classList.remove("active");
				}
			})
      punct.parentElement.classList.toggle("active");
    });
		punct.parentElement.querySelector('.icon-close')?.addEventListener("click", function () {
			punct.parentElement.classList.toggle("active");
		})
  });
});
