document.addEventListener('DOMContentLoaded', function() {
	document.querySelector('#scrollToTop')?.addEventListener('click', function() {
		window.scrollTo({
			top: 0,
			behavior: 'smooth'
		})
	})
	if(window.scrollY > 3000) {
		document.querySelector('#scrollToTop')?.classList.add('show')
	}
	document.addEventListener('scroll', function() {	
		if (window.scrollY > 3000) {
			document.querySelector('#scrollToTop')?.classList.add('show')
		} else {
			document.querySelector('#scrollToTop')?.classList.remove('show')
		}
	})
})