const links = document.querySelectorAll('.pages_headings-list__link');
if (links.length > 0) {
    // Додаємо клас 'active' першому посиланню за замовчуванням
    links[0].classList.add('active');

    links.forEach(link => {
        link.addEventListener('click', function (event) {
            event.preventDefault(); // щоб уникнути переходу по посиланню

            // Видаляємо клас 'active' у всіх посилань
            links.forEach(link => link.classList.remove('active'));

            // Додаємо клас 'active' до поточного посилання
            event.target.classList.add('active');

            const linkText = event.target.textContent.trim();
            const headers = document.querySelectorAll('.diagnostic-wrapper__content h3');
            if (headers.length > 0) {
                headers.forEach(header => {
                    if (header.textContent.trim() === linkText) {
                        header.scrollIntoView({ behavior: 'smooth' });
                    }
                });
            } else {
                console.warn('No headers found in .diagnostic-wrapper__content');
            }
        });
    });
} else {
    console.warn('No links found with class .pages_headings-list__link');
}