document.addEventListener("DOMContentLoaded", function () {
  const buttons = document.querySelectorAll(".app-button-js");
  const buttonClose = document.querySelector(".app-close-button");

  // Функція для закриття бекдропу
  function closeBackdrop() {
    const backdrop = document.getElementById("appointment-schedule");
    backdrop.classList.add("is-hidden");
    document.body.classList.remove("body-no-scroll");
  }

  // Додавання обробника подій кліку на кожен елемент, якщо елементи знайдено
  if (buttons.length > 0) {
    buttons.forEach((button) => {
      button.addEventListener("click", () => {
        const backdrop = document.getElementById("appointment-schedule");
        backdrop?.classList.remove("is-hidden");

        [
          { name: "town-name", attr: "data-townname" },
          { name: "doctor-name", attr: "data-doctorname" },
          { name: "event-time", attr: "data-eventtime" },
        ].forEach(({ name, attr }) => {
          if (button.getAttribute(attr)) {
            backdrop
              .querySelector(`form input[name="${name}"]`)
              ?.setAttribute("value", button.getAttribute(attr));
          }
        });
        document.body.classList.add("body-no-scroll");
      });
    });
  }

  // Додавання обробника подій на клік фону бекдропу, якщо елемент знайдено
  const backdrop = document.getElementById("appointment-schedule");
  if (backdrop) {
    backdrop.addEventListener("click", (event) => {
      if (event.target === backdrop) {
        // Закриваємо тільки якщо клік на фоні
        clearForm(["town-name", "doctor-name", "event-time"], backdrop);
        closeBackdrop();
      }
    });
  }

  // Додавання обробника подій на клік клавіші "Esc"
  document.addEventListener("keydown", (event) => {
    if (event.key === "Escape") {
      clearForm(["town-name", "doctor-name", "event-time"], backdrop);
      closeBackdrop();
    }
  });

  // Додавання обробника подій для кнопки закриття
  if (buttonClose) {
    buttonClose.addEventListener("click", () => {
      clearForm(["town-name", "doctor-name", "event-time"], backdrop);
      closeBackdrop();
    });
  }

  // Логіка для закриття бекдропу при розширенні більше 768px
  window.addEventListener("resize", () => {
    if (window.innerWidth > 768) {
      clearForm(["town-name", "doctor-name", "event-time"], backdrop);
      closeBackdrop();
    }
  });
});
function clearForm(arr, backdrop) {
  arr.forEach((el) => {
    backdrop
      .querySelector(`form input[name="${el}"]`)
      ?.setAttribute("value", "");
  });
}

const menuButtons = document.querySelectorAll(".menu-button");
const closeButton = document.querySelector(".button-close");
const menu = document.getElementById("menu");

// Функція для закриття меню
function closeMenu() {
  menu.classList.add("is-hidden");
  document.body.classList.remove("body-no-scroll");
}

// Функція для відкриття меню
function openMenu() {
  menu.classList.remove("is-hidden");
  document.body.classList.add("body-no-scroll");
}

// Додавання обробника подій кліку на кожну кнопку меню
menuButtons.forEach((button) => {
  button.addEventListener("click", () => {
    if (menu.classList.contains("is-hidden")) {
      openMenu();
    } else {
      closeMenu();
    }
  });
});

// Додавання обробника подій кліку на кнопку закриття меню
if (closeButton) {
  closeButton.addEventListener("click", () => {
    closeMenu();
  });
}

// Додавання обробника подій на клік фону меню, якщо елемент знайдено
if (menu) {
  menu.addEventListener("click", (event) => {
    if (event.target === menu) {
      closeMenu();
    }
  });
}

// Додавання обробника подій на клік клавіші "Esc"
document.addEventListener("keydown", (event) => {
  if (event.key === "Escape") {
    closeMenu();
  }
});

// Логіка для закриття меню при розширенні більше 768px
window.addEventListener("resize", () => {
  if (window.innerWidth > 768) {
    closeMenu();
  }
});
