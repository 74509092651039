let toggleClacces = (element = null, classes = []) => {
	if (!element) return;
	classes.forEach((className) => {
		element.classList.toggle(className);
	});
};
let hoverElelemts = document.querySelectorAll(".hoverElement");
hoverElelemts.forEach((element) => {
  let hoverItem = document.createElement("div");

  toggleClacces(hoverItem, ["hoverItem", "fadeOut"]);
  element.appendChild(hoverItem);

  element.addEventListener("mouseenter", () => {
    toggleClacces(hoverItem, ["fadeIn", "fadeOut"]);
    window.addEventListener("mousemove", Hovered);
  });
  element.addEventListener("mouseleave", () => {
    toggleClacces(hoverItem, ["fadeIn", "fadeOut"]);
    window.removeEventListener("mousemove", Hovered);
  });
  let Hovered = (e) => {
    hoverItem.style.setProperty("--_x", e.x + "px");
    hoverItem.style.setProperty("--_y", e.y + "px");
  };
});
