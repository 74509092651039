document.addEventListener("DOMContentLoaded", setProp);

window.addEventListener("resize", setProp);

function isElementInViewport(element, callback) {
  if (!element) return;

  const observerOptions = {
    root: null,
    rootMargin: "0px",
    threshold: 0.1,
  };

  const observer = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        callback(true);
      } else {
        callback(false);
      }
    });
  }, observerOptions);

  observer.observe(element);
}

function setProp() {
  if (window.innerWidth < 992) {
    return;
  }
  isElementInViewport(
    document.querySelector(".faq-list"),
    function (isVisible) {
      if (isVisible) {
        const maxHeight = [
          ...document.querySelectorAll(".faq-list .faq-list__item"),
        ].reduce((acc, item) => {
          const itemHeight = parseInt(getComputedStyle(item).height);
          return itemHeight > acc ? itemHeight : acc;
        }, 0);

        document.querySelectorAll(".faq-list").forEach((ul) => {
          ul.style.setProperty("--_min-height", `${maxHeight}px`);
        });
      }
    }
  );
}

