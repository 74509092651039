const videoElements = document.querySelectorAll("video");

function handleFullscreenChange() {
  videoElements.forEach((videoElement) => {
    if (
      document.fullscreenElement === videoElement ||
      document.webkitFullscreenElement === videoElement
    ) {
      videoElement.style.objectFit = "contain"; // Стиль для повноекранного режиму
      videoElement.style.objectPosition = "center";
    } else {
      videoElement.style.objectFit = "cover";
      videoElement.style.objectPosition = "";
    }
  });
}

document.addEventListener("fullscreenchange", handleFullscreenChange);
document.addEventListener("webkitfullscreenchange", handleFullscreenChange);
