import "bootstrap/dist/css/bootstrap.css";
import "modern-normalize/modern-normalize.css";

import "./utils";
import "./swiper";
import "./accordion";
import "./scrollHeaders";
import "./templatePages/swiper";
import "./menu";

import "./popups";
import "./cart";
import "./video";
import "./hoverEfect";
import "./cart-fliper";
import "./burger-menu-dropdown";
import "./scrollToTop";
import "./placeholder-changer";
import "./questions";
import "./hiddenImage";
import "./minicart/fetchProducts";
import "./mouseIconScroll";
import "./telegram";
import "./pixel";

import "../css/main.scss";
