import Swiper from "swiper/bundle";
import "swiper/css/bundle";

const methods = new Swiper('.methods-swiper', {
		slidesPerView: 1,
		spaceBetween: 12,
		breakpoints: {
			768: {
				slidesPerView: 3
			}
		},
		pagination: {
			el: ".controls .swiper-pagination",
			dynamicBullets: true,
			clickable: true,
		},
		navigation: {
			prevEl: ".controls .prev",
			nextEl: ".controls .next",
	},
})
 
const swiperCausesBottom = new Swiper(".causes-and-symptoms-swiper-bottom", {
    slidesPerView: 1,
    spaceBetween: 12,
    breakpoints: {
        768: {
            spaceBetween: 32,
            slidesPerView: 2,
        }
    },
    pagination: {
        el: ".swiper-pagination",
        dynamicBullets: true,
        clickable: true,
    },

    navigation: {
        prevEl: ".causes-and-symptoms-swiper-bottom .prev",
        nextEl: ".causes-and-symptoms-swiper-bottom .next",
    },

});
