// function updateCartItemCount() {
//     const cartItemCount = $('.cart-contents').find('.count').text();
//     $('.cart-item').text(cartItemCount);
// }
//
// // Виклик функції для оновлення при завантаженні сторінки
// $(document).ready(function() {
//     updateCartItemCount();
// });
//
// // Оновлення кількості при додаванні товару у корзину
// $(document.body).on('added_to_cart', function() {
//     updateCartItemCount();
// });