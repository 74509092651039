document.addEventListener("DOMContentLoaded", function () {
  let input = document.querySelector(
    '.main-nav__wrapper form input[type="search"]'
  );
  if (!input) return;
  input.setAttribute("placeholder", "Пошук...");
  if (document.documentElement.lang === "ua") {
    input.setAttribute("placeholder", "Пошук...");
  } else if (document.documentElement.lang === "ro") {
    input.setAttribute("placeholder", "Cautare...");
  } else {
    input.setAttribute("placeholder", "Search...");
  }
});
