document.addEventListener("DOMContentLoaded", () => {
	document.querySelectorAll(".app-button-js .icon-mouse").forEach((button) => {
			button.addEventListener("click", (e) => {
					e.stopPropagation();
					// Find the current section
					let currentSection = button.closest('section');

					// Get the next section
					let nextSection = currentSection.nextElementSibling;

					// Scroll to the next section if it exists
					if (nextSection) {
							nextSection.scrollIntoView({ behavior: 'smooth' });
					}
			});
	});
});
