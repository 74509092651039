const refs = {
  bodyEl: $("body"),

  // appButtons: $(".app-button-js"),
  // hideButton: $(".hide-app-button"),
  // appBackdrop: $("#appointment"),
  // appHideButton: $(".appointment-button"),
};

export default refs;
