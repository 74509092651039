// import {showBackdrop, hideBackdrop} from "./utils";
// import refs from "./refs";
//
// const {menuBackdrop, menuButton, appBackdrop, appButtons, hideButton, appBackdropVideo} =
//     refs;
//
// const handleAppButtonClick = () => {
//     if (!$(menuBackdrop).hasClass("is-hidden")) {
//         hideBackdrop(menuBackdrop);
//     }
//
//     showBackdrop(appBackdrop);
// };
//
// // const handleAppVideoButtonClick = () => {
// //     showBackdrop(appBackdropVideo); // Викликаємо showBackdrop для відображення бекдропу
// // };
//
// // Визначаємо функцію для обробки кліку на кнопку закриття бекдропу
// const handleHideButtonClick = () => {
//     hideBackdrop(appBackdropVideo); // Викликаємо hideBackdrop для приховування бекдропу
// };
//
// // Додаємо обробник кліку на кнопку відео
// // appVideoButton.on("click", handleAppVideoButtonClick);
//
// // Додаємо обробник кліку на кнопку закриття бекдропу
// hideButton.on("click", handleHideButtonClick);
//
//
// appButtons.on("click", handleAppButtonClick);
// hideButton.on("click", () => hideBackdrop(appBackdrop));
//
// menuButton.on("click", function () {
//     if ($(menuBackdrop).hasClass("is-hidden")) {
//         showBackdrop(menuBackdrop, true);
//     } else {
//         hideBackdrop(menuBackdrop);
//     }
// });
//
//
//
//
// // const observer = new MutationObserver((mutationsList, observer) => {
// //     for (const mutation of mutationsList) {
// //         if (mutation.type === "attributes" && mutation.attributeName === "class") {
// //             $(menuButton).toggleClass("is-active");
// //         }
// //     }
// // });
// //
// // observer.observe(menuBackdrop[0], {attributes: true});
