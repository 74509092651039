import Swiper from "swiper";

const consequences = new Swiper("#consequences .swiper", {
  slidesPerView: 1,
  navigation: {
    prevEl: "#consequences .prev",
    nextEl: "#consequences .next",
  },
});
[1, 2].forEach((i) => {
  const swiper = new Swiper(`#video_${i} .swiper`, {
    autoHeight: true,
    spaceBetween: 20,
    navigation: {
      prevEl: `#video_${i} .prev`,
      nextEl: `#video_${i} .next`,
    },
    pagination: {
      el: `#video_${i} .pagination`,
      clickable: true,
    },
  });
});
// const video_1 = new Swiper("#video_1 .swiper", {
//   navigation: {
//     prevEl: "#video_1 .prev",
//     nextEl: "#video_1 .next",
//   },
//   pagination: {
//     el: "#video_1 .pagination",
//     clickable: true,
//   },
// });
// const video_2 = new Swiper("#video_2 .swiper", {
//   navigation: {
//     prevEl: "#video_2 .prev",
//     nextEl: "#video_2 .next",
//   },
//   pagination: {
//     el: "#video_2 .pagination",
//     clickable: true,
//   },
// });
